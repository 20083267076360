import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
	const [opened, setOpened] = useState(false);

	return (
		<div className="sticky top-0 bg-white shadow-md">
			<div className="container mx-auto px-4 py-2 md:px-6 lg:px-8">
				<div className="flex justify-between items-center">
					<div className="shrink">
						<Link to="/" className="flex items-center">
							<div className="inline-block bg-gray-400 rounded-full mr-4 w-12 aspect-square"></div> Logo
						</Link>
					</div>
					<div className="shrink">
						<div className={`tham tham-e-squeeze tham-w-6 ${ opened ? 'tham-active' : ''}`} onClick={() => setOpened( !opened )}>
							<div className="tham-box">
								<div className="tham-inner" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}