import axios from "axios";

// Register user
const register = async ( userData ) => {
	// Send user data to backend to be processed
	const response = await axios.post( 'register', userData )

	// Log user in after successful registration
	if( register.data ) {
		const { email, password } = userData
		login( { email, password } )
	}

	// Store user data in localStorage if it is returned
	// if( response.data ) localStorage.setItem( 'user', JSON.stringify( response.data.data.user ) )

	// Return user data to browser
	return response.data.data.user
}

// Login user
const login = async ( userData ) => {
	// Send login details to be processed
	const response = await axios.post( 'login', userData )

	// Manipulate repsonse objects to make them earsier to work with
	let data = {
		...response.data.data.user,
		token: response.data.data.token,
	}

	// Return user data to browser (same as above)
	return data
}

// Logout user
const logout = async ( token ) => {
	// Send logout request
	const response = await axios.get( 'logout', {
		headers: {
			Authorization: `Bearer ${token}`
		}
	})

	console.log( response )

	// Log server response for debugging
	// console.log( response )

	// Remove user data from localStorage
	// localStorage.removeItem( 'user' )
	
	// return response.data
}

const authService = {
	register,
	login,
	logout
}

export default authService