import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import Container from "../components/Container"
import Grid from "../components/Grid"

import Button from "../components/Button"

function Home() {
	const { user } = useSelector(
		(state) => state.auth
	)

	return (
		<Container>
			<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow mb-6">

				<Grid>
					<div className="col-span-3 lg:col-span-9">
						<p className="text-3xl font-bold">Hello, { user.name }!</p>
					</div>
					<div className="col-span-1 lg:col-span-3">
						<Link to="/new-group">
							<Button>
								+ <span className="hidden sm:inline-block">New</span> Group
							</Button>
						</Link>
					</div>
				</Grid>
				
				<p className="text-xl font-semibold mb-2">Well done!</p>
				<p className="mb-4">You're still in with a chance to win in 3 of your 4 groups.</p>
			</div>

			<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow mb-6">
				<p className="text-xl font-semibold mb-4">Your Groups</p>
				<ul className="list-disc ml-4">
					<li>Oooo lad the mabz</li>
					<li>23/24 LMS</li>
					<li>Carbon Happy World</li>
					<li>
						<span className="text-red-300 line-through">Rugby World Cup LMS</span>
					</li>
				</ul>
			</div>

			<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow mb-6">
				<p className="text-xl font-semibold mb-2">Your Stats</p>
				<div className="grid grid-cols-2">
					<div className="col">
						<p className="text-lg">Groups Won</p>
						<p className="text-green-400">17</p>
					</div>
					<div className="col">
						<p className="text-lg">Groups Lost</p>
						<p className="text-red-400">34</p>
					</div>
				</div>
				<div className="col-span-2">
					<p className="text-xl font-semibold mb-2">Table Stats</p>
					<p className="mb-4">Your average table position of your pick is 7.</p>
				</div>
			</div>
			
			<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow mb-6">
				<p className="text-xl font-semibold mb-2">More Data</p>
				<div className="grid grid-cols-2 gap-x-4 lg:gap-x-6">
					<div className="col">
						<p className="text-lg">Your most picked teams</p>
						<p className="text-xs text-gray-500 italic mb-4">These are the teams you have picked the most across all of your groups.</p>
						<ol className="list-decimal ml-4">
							<li>Liverpool <span className="text-xs">(9)</span></li>
							<li>Everton <span className="text-xs">(9)</span></li>
							<li>Arsenal <span className="text-xs">(4)</span></li>
							<li>Manchester City <span className="text-xs">(3)</span></li>
							<li>Brighton <span className="text-xs">(3)</span></li>
						</ol>
					</div>
					<div className="col">
						<p className="text-lg">Your least picked teams</p>
						<p className="text-xs text-gray-500 italic mb-4">These are the teams you have picked the least across all of your groups.</p>
						<ol className="list-decimal ml-4">
							<li>Aston Villa <span className="text-xs">(0)</span></li>
							<li>Luton <span className="text-xs">(0)</span></li>
							<li>Manchester United <span className="text-xs">(0)</span></li>
							<li>Wolverhampton Wanderers <span className="text-xs">(1)</span></li>
							<li>Tottenham Hotspur <span className="text-xs">(1)</span></li>
						</ol>
					</div>
				</div>
			</div>
			
			<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow mb-6">
				<p className="text-xl font-semibold mb-6">World Leagues</p>
				<p className="mb-2">Only 3.7% of our users have made it past Gameweek 12. Well done!</p>
			</div>

		</Container>
	)
}

export default Home