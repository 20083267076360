import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import Input from "../components/Input"
import Button from "../components/Button"
import { register } from "../features/auth/authSlice";

function Register() {
	const dispatch = useDispatch()

	const { isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth
	)

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		confirm_password: '',
	})

	const { name, email, password, confirm_password } = formData

	const handleChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleRegister = e => {
		e.preventDefault()

		dispatch( register( formData ) )
	}

	useEffect(() => {
		isSuccess && alert( "Signed up!!!!" )
	}, [isSuccess])

	return (
		<div className="flex flex-col items-center justify-center h-full">

			<div className="bg-white px-6 py-6 lg:py-10 lg:px-8 rounded-lg shadow w-full max-w-sm">

				{isError && (
					<div className="bg-red-200 border border-red-400 rounded-lg p-4 mb-4">
						{ message }
					</div>
				)}

				<h2 className="text-center text-2xl font-bold leading-9 tracking-tight mb-6">Register</h2>

				<form className="space-y-6" onSubmit={handleRegister} onChange={handleChange}>
					<div>
						<label htmlFor="name" className="block text-sm font-medium leading-6">Your name</label>
						<div className="mt-2">
							<Input id="name" name="name" type="text" placeholder="Joe Bloggs" autoComplete="name" value={ name } required onChange={handleChange} />
						</div>
					</div>
					<div>
						<label htmlFor="email" className="block text-sm font-medium leading-6">Your email</label>
						<div className="mt-2">
							<Input id="email" name="email" type="text" placeholder="you@example.com" autoComplete="email" value={ email } required onChange={handleChange} />
						</div>
					</div>
					<div>
						<label htmlFor="password" className="block text-sm font-medium leading-6">Your password</label>
						<div className="mt-2">
							<Input id="password" name="password" type="password" placeholder="••••••••" autoComplete="password" value={ password } required onChange={handleChange} />
						</div>
					</div>
					<div>
						<label htmlFor="confirm_password" className="block text-sm font-medium leading-6">Confirm Password</label>
						<div className="mt-2">
							<Input id="confirm_password" name="confirm_password" type="password" placeholder="••••••••" autoComplete="confirm_password" value={ confirm_password } required onChange={handleChange} />
						</div>
					</div>
					<div>
						<Button type="submit">
							{ !isLoading ? (
								'Register' 
							) : (
								<FontAwesomeIcon icon={light('spinner-scale')} spin />
							)}
						</Button>
					</div>
				</form>

			</div>

		</div>
	)
}

export default Register