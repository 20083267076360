import React from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import axios from 'axios';

import App from './App';
// import reportWebVitals from './reportWebVitals';

import './index.css';

console.log(process.env.REACT_APP_API_BASE_URL)

axios.interceptors.request.use((request) => {
	// Update request URL to include backend domain
	request.url = `${process.env.REACT_APP_API_BASE_URL}${request.url}`

	// Return request object to axios
	return request
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals( console.log );