import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Container from "../components/Container"
import Grid from "../components/Grid"
import H1 from "../components/typography/H1"
import Button from "../components/Button"

function Groups() {
	const groups = useSelector(
		(state)	=>	state.groups
	)

	return (
		<>
			<Container>
				<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow">

					<Grid>
						<div className="col-span-2 col-start-3 lg:col-span-4">
							<Link to="/join-group">
								<Button>
									<FontAwesomeIcon className="mr-2" icon={solid('users-medical')} /> Join <span className="hidden sm:inline-block">a</span> Group
								</Button>
							</Link>
						</div>
					</Grid>
				</div>
			</Container>
			<Container>
				<div className="bg-white px-4 py-6 md:px-6 lg:px-8 rounded-lg shadow">

					<Grid>
						<div className="col-span-3 lg:col-span-9">
							<H1>Your Groups</H1>
						</div>
						<div className="col-span-1 col-start-4 lg:col-span-3 lg:col-start-10">
							<Link to="/new-group">
								<Button>
									+ <span className="hidden sm:inline-block">New</span> Group
								</Button>
							</Link>
						</div>

						<div className="col-span-4 lg:col-span-12">

							{ groups?.length ? (
								<ul className="list-disc">
									{ groups.map(( key, group ) => {
										return <li key={key}>{ group.name }</li>
									}) }
								</ul>
							) : (
								<p className="italic">You aren't currently a member of any active groups</p>
							)}
						</div>

					</Grid>
				
				</div>
			</Container>
		</>
	)
}

export default Groups