import { Routes, Route } from 'react-router-dom'
// import { useSelector } from "react-redux"

// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

import Header from "./components/Header"
import Footer from "./components/Footer"

import Home from "./pages/Home"
import Register from "./pages/Register"
import Login from "./pages/Login"
import Logout from "./pages/Logout"
import NewGroup from "./pages/NewGroup"
import Groups from "./pages/Groups"

function App() {	
	return (
		<>
			<div className="bg-gray-200 h-screen">

				<div className="flex flex-col h-full">

					<div className="shrink">
						<Header />
					</div>
							
					<div className="grow overflow-y-auto">
						<Routes>
							<Route path='/' element={<Login />} />
							<Route path='/dashboard' element={<Home />} />
							<Route path='/register' element={<Register />} />
							<Route path='/logout' element={<Logout />} />
							<Route path='/new-group' element={<NewGroup />} />
							<Route path='/groups' element={<Groups />} />
						</Routes>
					</div>

					<div className="shrink">
						<Footer />
					</div>
				
				</div>

			</div>
			
			{/* <ToastContainer /> */}
		</>
	);
}

export default App;