import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// Get user from localStorage
const initialState = {
	user: {},
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: ''
}

// Register user
export const register = createAsyncThunk(
	'auth/register',
	async ( user, thunkAPI ) => {
		// console.log( user )
		try {
			return await authService.register( user )
		} catch (error) {
			const message = ( error.response && error.response.data && error.response.data.message ) || error.message || error.toString()

			return thunkAPI.rejectWithValue( message )
		}
	}
)

// Login user
export const login = createAsyncThunk(
	'auth/login',
	async ( user, thunkAPI ) => {
		// console.log( user )
		try {
			return await authService.login( user )
		} catch( error ) {
			const message = ( error.response && error.response.data && error.response.data.message ) || error.message || error.toString()

			return thunkAPI.rejectWithValue( message )
		}
	}
)

// Logout user
export const logout = createAsyncThunk(
	'auth/logout',
	async ( _, thunkAPI ) => {
		const token = thunkAPI.getState().auth.user.token
		await authService.logout( token )
	}
)

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false
			state.isError = false
			state.isSuccess = false
			state.message = ''
		}
	},
	extraReducers: (builder) => {
		builder.addCase( register.pending, (state) => {
			state.isLoading = true
		}).addCase( register.fulfilled, (state, action) => {
			state.isLoading = false
			state.isSuccess = true
			state.user = action.payload
		}).addCase( register.rejected, (state, action) => {
			state.isLoading = false
			state.isError = true
			state.user = {}
			state.message = action.payload
		}).addCase( login.pending, (state) => {
			state.isLoading = true
		}).addCase( login.fulfilled, (state, action) => {
			state.isLoading = false
			state.isSuccess = true	
			state.user = action.payload
		}).addCase( login.rejected, (state, action) => {
			state.isLoading = false
			state.isError = true
			state.user = {}
			state.message = action.payload
		}).addCase( logout.pending, (state) => {
			state.isLoading = true
		}).addCase( logout.fulfilled, (state) => {
			state.isLoading = false
			state.isSuccess = true
			state.user = {}
		}).addCase( logout.rejected, (state, action) => {
			state.isLoading = false
			state.isError = true
			state.message = action.payload
		})
	}
})

export const { reset } = authSlice.actions
export default authSlice.reducer