import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

function Footer() {
	const auth = useSelector(
		(state) => state.auth
	)

	return (
		<div className="sticky bottom-0 bg-white shadow-lg">
			<div className="container mx-auto px-4 py-2 md:px-6 lg:px-8">

				<div className="grid grid-cols-4 gap-x-4 lg:gap-x-6">

					<NavLink to="/dashboard" className={({ isActive }) => `flex justify-center items-center border rounded-full h-8 aspect-square ml-auto mr-auto ${isActive ? 'border-indigo-500 bg-indigo-500 text-white' : '' }`}>
						<FontAwesomeIcon icon={light('gauge-high')} />
					</NavLink>

					<NavLink to="/groups" className={({ isActive }) => `flex justify-center items-center border rounded-full h-8 aspect-square ml-auto mr-auto ${isActive ? 'border-indigo-500 bg-indigo-500 text-white' : '' }`}>
						<FontAwesomeIcon icon={light('user-group')} />
					</NavLink>

					<NavLink to="/help" className={({ isActive }) => `flex justify-center items-center border rounded-full h-8 aspect-square ml-auto mr-auto ${isActive ? 'border-indigo-500 bg-indigo-500 text-white' : '' }`}>
						<FontAwesomeIcon icon={light('question')} />
					</NavLink>

					{ !!!Object.keys(auth.user).length ? (
						<NavLink to="/" className={({ isActive }) => `flex justify-center items-center border rounded-full h-8 aspect-square ml-auto mr-auto ${isActive ? 'border-indigo-500 bg-indigo-500 text-white' : '' }`}>
							<FontAwesomeIcon icon={light("arrow-right-to-arc")} />
						</NavLink>
					) : (
						<NavLink to="/logout" className={({ isActive }) => `flex justify-center items-center border rounded-full h-8 aspect-square ml-auto mr-auto ${isActive ? 'border-indigo-500 bg-indigo-500 text-white' : '' }`}>
							<FontAwesomeIcon icon={light("arrow-left-to-arc")} />
						</NavLink>
					)}

				</div>
			</div>
		</div>
	)
}

export default Footer