import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { login } from '../features/auth/authSlice'

import Input from '../components/Input'
import Button from '../components/Button'

function Login() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, isLoading, isSuccess, isError, message } = useSelector(
		(state) => state.auth
	)

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	})

	const { email, password } = formData

	const handleChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleLogin = e => {
		// Prevent page load on submit
		e.preventDefault()

		// Dispatch login request
		dispatch( login({ email, password }) )
	}

	// Redirect to dashboard on successful login
	useEffect(() => {
		if( isSuccess && !!Object.keys( user ).length )
			navigate( 'dashboard' )
	}, [isSuccess, user, navigate])
	return (
		<div className="flex flex-col items-center justify-center h-full">

			<div className="bg-white px-6 py-10 lg:px-8 rounded-lg shadow w-full max-w-xs">

				<div className="flex min-h-full flex-1 flex-col justify-center">
					<div className="sm:mx-auto sm:w-full sm:max-w-sm">
						<h2 className="text-center text-2xl font-bold leading-9 tracking-tight">Sign in</h2>
					</div>

					{isError && (
						<div className="bg-red-200 border border-red-400 rounded-lg p-4 mt-4">
							{ message }
						</div>
					)}

					<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
						<form className="space-y-6" onSubmit={handleLogin}>
							<div>
								<label htmlFor="email" className="block text-sm font-medium leading-6">Email address</label>
								<div className="mt-2">
									<Input id="email" name="email" type="email" placeholder="Email address" autoComplete="email" value={ email } required onChange={handleChange} />
								</div>
							</div>
							<div>
								<div className="flex items-center justify-between">
									<label htmlFor="password" className="block text-sm font-medium leading-6">Password</label>
									<div className="text-sm">
										<Link to="/login" className="font-semibold text-indigo-400 hover:text-indigo-300">
											Forgot password?
										</Link>
									</div>
								</div>
								<div className="mt-2">
									<Input id="password" name="password" type="password" placeholder="Password" autoComplete="current-password" value={ password } required onChange={handleChange} />
								</div>
							</div>
							<div>
								<Button type="submit">
									{ !isLoading ? (
										'Sign in' 
									) : (
										<FontAwesomeIcon icon={light('spinner-scale')} spin />
									)}
								</Button>
							</div>
						</form>

						<p className="mt-10 text-center text-sm text-gray-400">Not a member?{' '}
							<Link to="/register" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
								Need an account?
							</Link>
						</p>
					</div>
				</div>

			</div>

		</div>
	)
}

export default Login